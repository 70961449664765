import React, { useEffect, useState } from "react";
import { ArrowUpRight, ChevronLeft, ChevronRight, Circle } from "lucide-react";
import { Button, Box, Typography, IconButton, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Colors } from "styles/theme/color";
import { v4 as uuidv4 } from "uuid";

const slides = [
  {
    title: "RUMAH BISNIS MANDIRI SHOP",
    description: "Produk berkualitas khas UMKM Desa Ngringo",
    redirect: "/produk-umkm/0317143c-4596-452e-8400-763537121779",
    url: "https://i.ibb.co.com/1M4pqD1/Whats-App-Image-2024-09-01-at-18-32-48-1.jpg",
  },
  {
    title: "RUMAH BISNIS MANDIRI SHOP",
    description: "Produk berkualitas khas UMKM Desa Ngringo",
    redirect: "/produk-umkm/5756e547-49f6-469c-b291-768d478b8343",
    url: "https://i.ibb.co.com/gdGsxHL/Whats-App-Image-2024-09-01-at-18-32-44.jpg",
  },
  {
    title: "RUMAH BISNIS MANDIRI SHOP",
    description: "Produk berkualitas khas UMKM Desa Ngringo",
    redirect: "/produk-umkm/59253421-46a7-4032-a114-a430d1667475",
    url: "https://images.unsplash.com/photo-1695712641569-05eee7b37b6d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const CarouselContainer = styled(Container)({
  position: "relative",
  maxWidth: "1400px",
  padding: "16px 4px",
});

const ImageWrapper = styled(Box)(({ url }) => ({
  width: "100%",
  height: "360px",
  borderRadius: "20px",
  backgroundImage: `url(${url})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
  transition: "700ms",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: Colors.primaryColorBlue100,
  },
}));

const BlurredBox = styled(Box)({
  borderRadius: "20px",
  margin: "0px 20px",
  backdropFilter: "blur(7px)",
  height: "74%",
  width: "290px",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "background-color 0.5s",
  "&:hover": {
    background: Colors.primaryOrange100,
  },
});

const ControlButton = styled(IconButton)({
  color: Colors.white,
});

const DotButton = styled(IconButton)({
  cursor: "pointer",
  color: Colors.primaryOrange100,
});

function CustomCarosel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const navigate = useNavigate();

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isHover) {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      }
    }, 9000);
    return () => clearInterval(intervalId);
  }, [currentIndex, isHover]);

  return (
    <CarouselContainer>
      <ImageWrapper
        url={slides[currentIndex].url}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <BlurredBox>
          <Typography variant="h5" color="white" gutterBottom>
            {slides[currentIndex].title}
          </Typography>
          <Typography variant="body1" color="white">
            {slides[currentIndex].description}
          </Typography>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              padding: "8px 16px",
              color: Colors.white,
              borderColor: Colors.white,
              transition: "0.5s all ease",
              "&:hover": { transform: "scale(1.03)" },
            }}
            onClick={() => navigate(slides[currentIndex].redirect)}
          >
            Detail <ArrowUpRight size="20px" style={{ marginLeft: "4px" }} />
          </Button>
        </BlurredBox>
      </ImageWrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          position: "absolute",
          top: "74%",
          right: "3%",
          zIndex: 10,
        }}
      >
        <ControlButton onClick={prevSlide}>
          <ChevronLeft />
        </ControlButton>
        <Typography variant="body2" color="white">
          {`${currentIndex + 1}/${slides.length}`}
        </Typography>
        <ControlButton onClick={nextSlide}>
          <ChevronRight />
        </ControlButton>
      </Box>
      <Box
        sx={{ justifyContent: "center", display: "flex", paddingTop: "5px" }}
      >
        {slides.map((_slide, index) => (
          <DotButton key={uuidv4()} onClick={() => goToSlide(index)}>
            {index === currentIndex ? (
              <Circle size={16} />
            ) : (
              <Box
                bgcolor={Colors.primaryOrange100}
                height={16}
                width={16}
                borderRadius={16}
              />
            )}
          </DotButton>
        ))}
      </Box>
    </CarouselContainer>
  );
}

export default CustomCarosel;
